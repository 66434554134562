import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
const lodash = require('lodash');
const axios = require('axios').default;
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import {v4 as uuidv4} from "uuid";

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

import Storage from 'vue-ls';
let options = {
  namespace: 'vuejs__', // key prefix
  storage: 'local', // storage name session, local, memory
};

import wb from "./registerServiceWorker";

Vue.prototype.$workbox = wb;

Vue.use(Storage, options);

Vue.config.productionTip = false

const databases = Vue.observable({ databases: [] })
Object.defineProperty(Vue.prototype, '$databases', {
  get () {
    return databases.databases
  },
  set (value) {
    databases.databases = value
  }
})

const userSettings = Vue.observable({ userSettings: {} })
Object.defineProperty(Vue.prototype, '$userSettings', {
  get () {
    return userSettings.userSettings
  },
  set (value) {
    userSettings.userSettings = value
  }
})

const emailQueue = Vue.observable({ emailQueue: {} })
Object.defineProperty(Vue.prototype, '$emailQueue', {
  get () {
    return emailQueue.emailQueue
  },
  set (value) {
    emailQueue.emailQueue = value
  }
})

const runTime = Vue.observable({
  runTime: {
    continueDialog: false,
    isOnline: navigator.onLine,
  }
})
Object.defineProperty(Vue.prototype, '$runTime', {
  get () {
    return runTime.runTime
  },
  set (value) {
    runTime.runTime = value
  }
})

Vue.prototype.$project = {
  database: {
    getById(id){
      return Object.assign({}, databases.databases.find(database => database.id.toString() === id.toString()));
    },
    updateById(id, database){
      let edited = false;
      databases.databases = databases.databases.map(function(current_database){
        if(current_database.id.toString() === database.id.toString()){
          edited = true;
          database.last_update = Date.now();

          return database;
        }

        return database;
      });
      this.forceSave();
      return edited;
    },
    deleteById(id) {
      databases.databases = databases.databases.filter(function(database) {
        if (database.id.toString() !== id.toString()) {
          return database;
        }
      });
    },
    getModel(){
      return {
          id: uuidv4(),
          name: '',
          is_open: true,
          last_update: Date.now(),
      }
    },
    forceSave(){
      Vue.ls.set('databases', databases.databases);
    }
  },
  userSettings: {
    getModel(){
      return {
        barcode_instant_save: '',
        barcode_instant_delete: '',
        compact_mode: true,
        selected_video_source: null,
        container: {
          auto_prepend_with_prefix: true,
          has_prefix: 'auto',
          has_prefix_options: [
            {
                key: 'auto',
                name: 'Auto'
            },
            {
                key: 'yes',
                name: 'Igen'
            },
            {
                key: 'no',
                name: 'Nem'
            },
          ],
          prefix: 'HU',
        },
        notify: {
          api_key: null,
          email: null,
          baseURL: 'https://api.barcode-database.engineed.hu/api/v1/'
        },
      }
    },
  },
  emailQueue: {
    getModel(){
      return {
        last_try: null,
        jobs: []
      }
    },
    getJobById(id){
      return Object.assign({}, emailQueue.emailQueue.jobs.find(job => job.id.toString() === id.toString()));
    },
    updateJobById(id, job){
      let edited = false;
      emailQueue.emailQueue.jobs = emailQueue.emailQueue.jobs.map(function(current_job){
        if(current_job.id.toString() === job.id.toString()){
          return job;
        }

        return current_job;
      });

      this.forceSave();

      return edited;
    },
    forceSave(){
      Vue.ls.set('emailQueue', emailQueue.emailQueue);
    },
    resetAllBusy(){
      emailQueue.emailQueue.jobs.filter(job => job.is_busy === true).map(function(job){
        job.is_busy = false;

        return job;
      });
    },
    waitingJobCount(){
      return emailQueue.emailQueue.jobs.filter(job => job.is_processed === false).length;
    },
    async createJob(database){
      let job = {
        id: uuidv4(),
        database: database,
        retries: 0,
        is_processed: false,
        is_busy: false,
      };

      emailQueue.emailQueue.jobs.push(job);

      if(runTime.runTime.isOnline === false){
        return false;
      }

      return await this.processJob(job.id);
    },
    async processJob(id){
      let job = this.getJobById(id);

      if(!job){
        console.log('Job not found with ID: '. id);
      }

      job.is_busy = true;
      this.updateJobById(job.id, job);

      console.log(userSettings.userSettings);
      axios.defaults.baseURL = userSettings.userSettings.notify.baseURL;
      axios.defaults.headers.common['Authorization'] = 'Bearer '+ userSettings.userSettings.notify.api_key;
      axios.defaults.headers.post['Content-Type'] = 'application/json';

      let isSuccess = false;

      try {
        let result = await axios.post('/email/send', {
          email: userSettings.userSettings.notify.email,
          timeout: 8000,
          data: {
            database: job.database
          },
          subject: 'Adatbázis lezárva: '+ job.database.name,
          body: 'A következő adatbázis sikeresen lezárva: '+ job.database.name
        });

        if(result.status === 200){
          isSuccess = true;
          job.is_processed = true;
        }
      }
      catch (err) {
        console.error(err);
      }

      if(!isSuccess){
        job.retries++;
      }
      job.is_busy = true;
      this.updateJobById(job.id, job);

      return isSuccess;
    }
  }
}


new Vue({
  router,
  render: h => h(App),
  beforeMount() {
    this.$userSettings = lodash.merge(this.$project.userSettings.getModel(), this.$ls.get('userSettings'));
    this.$databases = lodash.merge([], this.$ls.get('databases'));
    this.$emailQueue = lodash.merge(this.$project.emailQueue.getModel(), this.$ls.get('emailQueue'));
    this.$project.emailQueue.resetAllBusy();
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
  },
  methods: {
    updateOnlineStatus(e) {
      console.log(e);
      const {
        type
      } = e;
      this.$runTime.isOnline = type === 'online';
    },
    dispatchNextEmailJob(){

      if(this.$userSettings.notify.api_key.length  < 2 || this.$userSettings.notify.email.length < 2){
        return;
      }

      let jobs = this.$emailQueue.jobs.filter(job => job.is_processed === false &&  job.is_busy === false);
      let job = null;

      if(jobs.length){
        job = jobs.pop();
      }

      if(!job){
        return;
      }

      this.$project.emailQueue.processJob(job.id).then(result => {
        console.log(result);
      });
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    const vue = this;
    setInterval(function(){
        if(!vue.$runTime.isOnline ){
          return console.log("Not online");
        }

      vue.dispatchNextEmailJob();
    }, 5000);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  async accept() {
    this.showUpdateUI = false;
    await this.$workbox.messageSW({ type: "SKIP_WAITING" });
  },
  watch: {
    $userSettings: {
      handler(){
        this.$ls.set('userSettings', this.$userSettings);
      },
      deep: true
    },
    $emailQueue: {
      handler(){
        this.$ls.set('emailQueue', this.$emailQueue);
      },
      deep: true
    },
    $databases: {
      handler(){
        this.$project.database.forceSave();
      },
      deep: true
    }
  }
})
.$mount('#app')

