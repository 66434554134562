<template>
  <div>
    <div id="video-container"  v-if="$isMobile()" :class="{'mt-1': compactMode, 'mt-3': !compactMode}">
      <video id="video"></video>
    </div>
    <div id="scan-form-container" :class="{'is-mobile': $isMobile(),'p-1 compact-form': compactMode}">
      <div class="container" :class="{'p-0': compactMode}">
        <div class="text-center" :class="{'mb-1': compactMode, 'mb-3': !compactMode}">
          <span class="read-count">Beolvasott kódok: {{ barcodes.length}}</span>
        </div>
        <form class="" novalidate autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false">
            <div class="form-group">
              <div class="input-group">
              <input type="text"
                     inputmode="none"
                     v-model="container"
                     name="container"
                     placeholder="Krotália kódja"
                     ref="inputContainer"
                     class="form-control"
                     :class="{ 'is-invalid': $v.container.$invalid, 'is-valid': !$v.container.$invalid, 'focus': focused === 'inputContainer', 'form-control-lg': !compactMode}"
                     v-model.trim="container"
                     @focus="focused = 'inputContainer'" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-danger m-0" @click="container = ''; setFocus('inputContainer')" :class="{'btn-sm': compactMode}"><i class="fa fa-times"></i></button>
                </div>
                <div class="invalid-feedback" v-if="!$v.container.containerRegex">Az azonosító formátuma: <span v-if="$userSettings.container.has_prefix === 'yes' || $userSettings.container.has_prefix === 'auto'">{{ $userSettings.container.prefix }}</span>0123456789</div>
              </div>
            </div>

            <div class="form-group" >
              <div class="input-group">
                <input type="text"
                       inputmode="none"
                       v-model="tube"
                       name="tube"
                       placeholder="Vérvételi cső kódja"
                       ref="inputTube"
                       class="form-control"
                       :class="{ 'is-invalid': $v.tube.$invalid, 'is-valid': !$v.tube.$invalid, 'focus': focused === 'inputTube', 'form-control-lg': !compactMode}"
                       v-model.trim="tube"
                       @focus="focused = 'inputTube'" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-danger m-0" @click="tube = ''; setFocus('inputTube')" :class="{'btn-sm': compactMode}"><i class="fa fa-times"></i></button>
                </div>
                <div class="invalid-feedback" v-if="!$v.tube.tubeRegex">Az azonosító formátuma: A12345678</div>
                <div class="invalid-feedback" v-if="!$v.tube.notSame">A krotália és a vérvételi cső azonosítójának különböznie kell</div>

              </div>
              <input type="text"
                     inputmode="none"
                     v-model="task"
                     name="task"
                     ref="inputTask"
                     class="form-control"
                     v-model.trim="task"
                     style="height: 0; padding: 0; border: 0; opacity: 0"
                     @focus="focused = 'taskTube'" />
            </div>

              <div class="row" :class="{'g-1 mx-n1 compact-button': compactMode, 'mx-n2': !compactMode}">
                <div :class="{'col px-1': compactMode, 'px-2 col': !compactMode}">
                  <button type="button" class="btn btn-danger btn-block" :class="{'btn-lg': !compactMode}" @click="barcodeClear" :disabled="$v.container.$invalid">Elvetés</button>
                </div>
                <div :class="{'col px-1': compactMode, 'px-2 col': !compactMode}">
                  <button type="button" class="btn btn-success btn-block" :class="{'btn-lg': !compactMode}" @click="barcodeSave" :disabled="$v.$invalid">Rögzít</button>
                </div>
              </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import {BrowserMultiFormatReader, NotFoundException, BarcodeFormat, DecodeHintType} from '@zxing/library';
import { required, helpers, not, sameAs } from 'vuelidate/lib/validators'

const tubeRegex = helpers.regex('tubeRegex', /^[A-Z]\d{8}$/);

const hints = new Map();
const formats = [BarcodeFormat.CODE_39, BarcodeFormat.CODE_93, BarcodeFormat.CODE_128, BarcodeFormat.EAN_8, BarcodeFormat.EAN_13];
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)
const codeReader = new BrowserMultiFormatReader(hints, 2000);
const readSound = new Audio(require('/public/sounds/read.mp3'));
readSound.volume = 1;

export default {
  name: "ScanForm",
  props : [
      "barcodes"
  ],
  data(){
    return {
      container: '',
      tube: '',
      task: '',
      videoSources : [],
      selectedVideoSource: null,
      lastScanned: '',
      lastClipboard: '',
      focused: 'inputContainer',
      compactMode: this.$userSettings.compact_mode
    }
  },
  validations(){
    let containerRegex = helpers.regex('containerRegex', /^\d{10}$/);

    if (this.$userSettings.container.has_prefix === "yes") {
      containerRegex = helpers.regex('containerRegex', new RegExp("^[" + this.$userSettings.container.prefix + "]{2}\\d{10}$"));
    }
    if (this.$userSettings.container.has_prefix === "auto") {
      containerRegex = helpers.regex('containerRegex', new RegExp("^[" + this.$userSettings.container.prefix + "]{2}\\d{10}$|^\\d{10}$"));
    }

    return {
      container: {
        required,
        containerRegex
      },
      tube: {
        required,
        tubeRegex,
        notSame: not(sameAs('container'))
      }
    }
  },
  mounted() {
    this.setFocus("inputContainer");

    if(this.$isMobile()){
      this.selectedVideoSource = this.$userSettings.selected_video_source
      this.initCapture();
    }
  },
  methods: {
    setFocus: function(name){
      if(this.$isMobile()){
        this.$refs[name].setAttribute('inputmode', 'none');
      }

      this.focused = name;
      this.$refs[name].focus();
      if(this.$isMobile()){
        const component = this;
        setTimeout(function(){
          component.$refs[name].setAttribute('inputmode', 'text');
        }, 100)

      }
    },
    barcodeSave: function(){
      if(this.$userSettings.container.auto_prepend_with_prefix){
        if(!this.container.startsWith(this.$userSettings.container.prefix)){
          this.container = this.$userSettings.container.prefix + this.container;
        }
      }

      this.$v.$touch();

      if (this.$v.$invalid) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Hibás kitöltés!',
          text: 'Nem menthetőek az azonosítók!',
          showCancelButton: true,
          cancelButtonText: 'Vissza'
        });
      }

      if (this.barcodes.find(element => element.container === this.container)) {
        return this.$swal.fire({
          icon: 'warning',
          title: 'A krotália már létezik!',
          text: this.container,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Vissza',
          confirmButtonText: 'Elvetés',
        }).then((result) => {
          if (result.isConfirmed) {
            this.barcodeClear(true);
          }
        });
      }

      if (this.barcodes.find(element => element.tube === this.tube)) {
        return this.$swal.fire({
          icon: 'warning',
          title: 'Az vérvételi cső már létezik!',
          text: this.tube,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Vissza',
          confirmButtonText: 'Elvetés',
        }).then((result) => {
          if (result.isConfirmed) {
            this.barcodeClear(true);
          }
        });
      }


      const newBarcodes = {
        id: uuidv4(),
        container: this.container,
        tube: this.tube
      }

      this.$parent.$emit('barcodes.created', newBarcodes);
      this.$forceUpdate();
      this.barcodeClear(true);

      this.$swal.fire({
        icon: 'success',
        title: 'Az azonosítók mentve!',
        toast: true,
        timer: 3000,
        timerProgressBar: true,
        position: 'top-end',
        showConfirmButton: false,
      });


      return false;
    },
    barcodeClear: function(byPassConfirm = false){

      if(byPassConfirm){
        this.container = "";
        this.tube = "";
        this.task= "";
        this.setFocus("inputContainer");

        return;
      }

      this.$swal.fire({
        title: 'Biztosan elvedet az azonosítót?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Igen',
        cancelButtonText: 'Nem'
      }).then((result) => {
        if (result.isConfirmed) {
          this.container = "";
          this.tube = "";
          this.setFocus("inputContainer");
        }
      })

    },
    startCapture: function(){
      codeReader.reset();
      codeReader.decodeFromVideoDevice(this.selectedVideoSource, 'video', (result, err) => {
        if (result) {
          readSound.pause();
          readSound.currentTime = 0;
          readSound.play();
          if(result.getText() === this.$userSettings.barcode_instant_save){
            return this.barcodeSave();
          }

          if(result.getText() === this.$userSettings.barcode_instant_delete){
            return this.barcodeClear(true);
          }

          if(result.getText() === this.lastScanned){
            this.$swal.fire({
              icon: 'error',
              title: 'Az előző kód is ugyanez volt.',
              toast: true,
              timer: 3000,
              timerProgressBar: true,
              position: 'top-end',
              showConfirmButton: false,
            });

            return;
          }

          this.handleIncomingBarcode(result.getText());
        }
        if (err && !(err instanceof NotFoundException)) {
          console.error(err)
        }
      })
      console.log(`Started continous decode from camera with id ${this.selectedVideoSource}`)
    },
    initCapture: function(){
      const component = this;
      codeReader.listVideoInputDevices().then(videoInputDevices => {
        if (videoInputDevices.length === 0) {
          if(this.$isMobile()) {
            component.$swal.fire({
              icon: 'error',
              title: 'Nem található kamera a mobil eszközön',
              text: 'Amennyiben folytatni szeretnéd manuálisan módban, kattins a folytatás gombra.',
              showConfirmButton: true,
              confirmButtonText: "Folytatás"
            });
          }

          return;
        }
        videoInputDevices.forEach((element) => {
          this.videoSources.push({
            id: element.deviceId,
            label: element.label
          });
        })

        if(component.selectedVideoSource !== null) {
          return component.startCapture();
        }

        navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: {
              exact: 'environment'
            }
          }
        }).then(function(device){
          component.$swal.fire({
            icon: 'success',
            title: 'Hátsó kamera beállítva!',
            toast: true,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
          });

          component.selectedVideoSource = device.deviceId
          component.startCapture();
        }).catch(function(error){
          component.$swal.fire({
            icon: 'error',
            title: 'Nem található hátsó kamera, a következő kiválasztva: ' + component.videoSources[0].label,
            timer: 3000,
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
          });
          console.log(error);
          component.selectedVideoSource = component.videoSources[0].id;
          component.startCapture();
        });
      });
    },
    handleIncomingBarcode(barcode){
      if(this.focused === 'inputContainer'){
        this.container = barcode
        if(!this.container.$invalid){
          this.setFocus("inputTube")
        }
      }else{
        this.tube = barcode
        if(!this.tube.$invalid){
          this.setFocus("inputTask");
        }
      }

      this.lastScanned = barcode
    }
  },
  watch: {
    container: {
      handler(){
        if(!this.$v.container.$invalid){
          this.setFocus('inputTube')
        }
      }
    },
    tube: {
      handler(){
        if(!this.$v.tube.$invalid){
          this.setFocus('inputTask')
        }
      }
    },
    task: {
      handler(task){
        if(this.$userSettings.barcode_instant_save.length && task === this.$userSettings.barcode_instant_save){
          return this.barcodeSave();
        }

        if(this.$userSettings.barcode_instant_delete.length && task === this.$userSettings.barcode_instant_delete){
          return this.barcodeClear(true);
        }

        this.task = "";
      }
    }
  }
}
</script>

<style scoped>
#scan-form-container{
  padding-top: 3rem;
  padding-bottom: 3rem;
}
#scan-form-container.is-mobile{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  -webkit-box-shadow: 0 -2px 5px 0 rgb(0 0 0 / 16%), 0 -2px 10px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 -2px 5px 0 rgb(0 0 0 / 16%), 0 -2px 10px 0 rgb(0 0 0 / 12%);
}
#scan-form-container .read-count{
  font-size: 16px;
  font-weight: bold;
}
video {
  width: 100%    !important;
  max-width: 100%;
  height: auto   !important;
}
.form-control.focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.form-control.is-invalid.focus, .was-validated .form-control:invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}
.compact-form .form-group{
  margin-bottom: 0.5rem;
}
</style>