<template>
  <div>
    <DatabaseList />
    <DatabaseForm />
  </div>
</template>

<script>
// @ is an alias to /src
import DatabaseList from '@/components/database/DatabaseList.vue'
import DatabaseForm from '@/components/database/DatabaseForm.vue'
import router from "@/router";

export default {
  name: 'Databases',
  components: {
    DatabaseList,
    DatabaseForm
  },
  mounted() {
    if(this.$runTime.continueDialog){
      return;
    }

    this.$runTime.continueDialog = true;

    let lastOpen = this.$databases.find(database => database.is_open === true);

    if(lastOpen){
      return this.$swal.fire({
        text: 'Folytatod az adatbázist? Amennyiben új adatbázist hozol létre az előző adatbázis lezárásra kerül.',
        title: lastOpen.name,
        icon: 'info',
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: 'Meglévők böngészése',
        confirmButtonText: `Legutolsó folytatása`,
        denyButtonText: 'Új létrehozása',
      }).then((result) => {
        if (result.isConfirmed) {
          return router.push("manage/"+ lastOpen.id)
        } else if (result.isDenied) {
          return this.$emit('database.open.form', null)
        }
      });
    }

    this.$swal.fire({
      title: 'Nincs folytatható adatbázis',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Meglévők böngészése',
      confirmButtonText: 'Új létrehozása',
    }).then((result) => {
      if (result.isConfirmed) {
        return this.$emit('database.open.form', null)
      }
    });
  },
}
</script>
