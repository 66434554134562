<template>
  <mdb-container >
    <mdb-modal :show="showFormModal" @close="showFormModal = false">
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">Adatbázis szerkesztése</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3 grey-text">
        <div class="form-group">
          <input type="text"
                 v-model="database.name"
                 name="database.name"
                 placeholder="Adatbázis neve"
                 class="form-control form-control-lg"
                 :class="{ 'is-invalid': $v.database.name.$invalid }"
                 v-model.trim="database.name"
          />
          <div class="invalid-feedback" v-if="!$v.database.name.require">Kötelező kitölteni</div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn  class="btn-primary" @click="saveDatabase">Mentés</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbBtn, mdbModal, mdbModalHeader, mdbModalBody, mdbModalFooter, mdbModalTitle } from 'mdbvue';
import router from "@/router";
import { required } from 'vuelidate/lib/validators'

export default {
  name: "DatabaseForm",
  components: {
    mdbContainer,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbModalTitle,
  },
  data(){
    return {
      showFormModal: false,
      database: this.$project.database.getModel(),
      is_update: false
    }
  },
  validations: {
    database: {
      name: {
        required
      }
    }
  },
  mounted(){
    this.$parent.$on('database.open.form', (id, is_update = false) => {

      this.showFormModal = true;
      this.is_update = is_update;

      if(id === null){
        return this.database = this.$project.database.getModel()
      }

      this.database = this.$project.database.getById(id);
    })
  },
  methods: {
    saveDatabase: function(){
      let lastOpen = this.$databases.find(database => database.is_open === true);

      if(lastOpen && !this.is_update){
        return this.$swal.fire({
          text: 'A fenti adatbázis még nyitva van. Amennyiben létrehozza az új adatbázist minden nyitott adatbázis lezárásra kerül.',
          title: lastOpen.name,
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: 'Mégsem',
          confirmButtonText: `Mindenképpen hozza létre`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateOrCreate();
          }
        });
      }
      return this.updateOrCreate();
    },
    updateOrCreate(){
        let formData = Object.assign({}, this.database)
        let edited = false;

        this.$databases = this.$databases.map(function(database){
          if(database.id.toString() === formData.id.toString()){
            edited = true;
            formData.last_update = Date.now();
            return formData;
          }
          database.is_open = false;
          return database;
        });

        if(!edited){
          this.$databases.unshift(formData);
        }

        if(!this.is_update){
          router.push("manage/"+ formData.id)
        }

        this.resetData();
        this.showFormModal = false;

        this.$parent.$emit('database.saved');
    },
    resetData(){
      this.database = this.$project.database.getModel();
    }
  }
}
</script>

<style scoped>

</style>