import { Workbox } from "workbox-window";

let wb;

if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("controlling", () => {
    console.log("wb event controlling");
    window.location.reload();
  });

  wb.register();

  setInterval(function(){
    wb.update()
  }, 5000);

} else {
  wb = null;
}

export default wb;
