import Vue from 'vue'
import VueRouter from 'vue-router'
import Databases from '../views/Databases.vue'
import Manage from '../views/Manage.vue'
import Settings from "@/views/Settings";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Adatbázisok',
    component: Databases,
  },
  {
    path: '/settings',
    name: 'Beállítások',
    component: Settings,
    props: true
  },
  {
    path: '/manage/:id',
    name: 'Beolvasás',
    component: Manage,
    props: true
  }
]

const router = new VueRouter({
  routes
})

export default router
