<template>
  <div>
    <div v-if="$isMobile() && !compactMode" :class="{'mb-1': compactMode, 'mb-3': !compactMode}">
      <button type="button" class="btn btn-info btn-block" @click="showListModal = true">Azonosítók megtekintése</button>
    </div>
    <div class="row" :class="{'g-1 mx-n1 compact-button': compactMode, 'mx-n2': !compactMode}">
      <div :class="{'col-4 px-1': compactMode, 'px-2 col-6': !compactMode}">
        <button type="button" class="btn btn-secondary btn-block" :class="{'btn-lg': !$isMobile()}" @click="pauseDatabase">Olvasás befejezése</button>
      </div>
      <div class="col-4 px-1" v-if="compactMode">
        <button type="button" class="btn btn-info btn-block" @click="showListModal = true">Azonosítók megtekintése</button>
      </div>
      <div :class="{'col-4 px-1': compactMode, 'px-2 col-6': !compactMode}">
        <button type="button" class="btn btn-primary btn-block" :class="{'btn-lg': !$isMobile()}" @click="closeDatabase">Mintavétel lezárása</button>
      </div>
    </div>

    <ScanForm :barcodes="database.barcodes" />
    <div v-if="!$isMobile()" class="mt-3">
      <table class="table table-striped table-bordered" v-if="database.barcodes !== undefined">
        <thead>
          <tr>
            <th>Krotália</th>
            <th>Vércső</th>
            <th>Törlés</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="barcode in database.barcodes" v-bind:key="barcode.id">
          <td>{{ barcode.container}}</td>
          <td>{{ barcode.tube}}</td>
          <td>
            <button type="button" class="btn btn-danger btn-sm m-0" @click="deleteBarcode(barcode.id)"><i class="fa fa-times"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <mdb-modal :show="showListModal" @close="showListModal = false">
      <mdb-modal-header class="text-left">
        <mdb-modal-title tag="h4" bold class="w-100">Azonosítók</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="grey-text">
        <table class="table table-striped" v-if="database.barcodes !== undefined">
          <thead>
          <tr>
            <th>Krotália</th>
            <th>Vércső</th>
            <th>Törlés</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="barcode in database.barcodes" v-bind:key="barcode.id">
            <td>{{ barcode.container}}</td>
            <td>{{ barcode.tube}}</td>
            <td>
              <button type="button" class="btn btn-danger btn-sm m-0" @click="deleteBarcode(barcode.id)"><i class="fa fa-times"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn  class="btn-primary" @click="showListModal = false">Bezárás</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import ScanForm from '../components/ScanForm.vue'
import router from "@/router";

import {mdbBtn, mdbModal, mdbModalBody, mdbModalFooter, mdbModalHeader, mdbModalTitle} from "mdbvue";


export default {
  name: "Manage",
  components: {
    ScanForm,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbModalTitle,
  },
  props: [
      'id'
  ],
  data() {
    return {
      database: null,
      showListModal: false,
      compactMode: this.$userSettings.compact_mode
    }
  },
  created() {
    this.database = this.$project.database.getById(this.id);

    if(this.database.barcodes === undefined){
      return this.database.barcodes = [];
    }
  },
  mounted() {
    this.$on('barcodes.created', function(barcode){
      this.database.barcodes.unshift(barcode);
      this.database.last_update = Date.now();
      this.$forceUpdate();
    });
  },
  methods: {
    deleteBarcode: function(id){
      this.$swal.fire({
        title: 'Biztosan törölni szeretnéd?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Igen',
        cancelButtonText: 'Nem'
      }).then((result) => {
        if (result.isConfirmed) {
          this.database.barcodes = this.database.barcodes.filter(function(database){
            if(database.id.toString() !== id.toString()){
              return database;
            }
          });
        }
      })
    },
    closeDatabase: function(){
      this.$swal.fire({
        title: 'Biztosan lezárod?',
        text: 'A lezárás után nincs lehetőségi további azonosítók hozzáadására.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Igen',
        cancelButtonText: 'Nem'
      }).then((result) => {
        if (result.isConfirmed) {
          this.database.is_open = false;
          this.database.last_update = Date.now();

          this.$swal.fire({
            toast: true,
            icon: 'success',
            title: 'Sikeres lezárás',
            position: 'top-end',
            showConfirmButton: false,
          });

          this.$project.emailQueue.createJob(this.database).then(result => {
            if(result === true){
              return this.$swal.fire({
                toast: true,
                icon: 'success',
                title: 'E-mailben sikeresen továbbítva!',
                position: 'top-end',
                showConfirmButton: false,
              });
            }

            this.$swal.fire({
              toast: true,
              icon: 'warning',
              title: 'Az e-mail továbbítva lesz, amennyiben újra csatlakozol az internethez.',
              position: 'top-end',
              showConfirmButton: false,
            });
          });

          router.push('/');
        }
      })
    },
    pauseDatabase: function(){
      this.$swal.fire({
        title: 'Biztosan befejezed az olvasást?',
        text: 'Később folytathatod a leolvasást, ahol abbahagytad.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Igen',
        cancelButtonText: 'Nem'
      }).then((result) => {
        if (result.isConfirmed) {
          router.push('/');
        }
      })
    },
  },
  watch: {
    database: {
      handler(update){
        console.log(update);
        this.$databases = this.$databases.map(function(database){
          if(database.id.toString() === update.id.toString()){
            return update;
          }

          return database;
        });
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
 table td{
   vertical-align: middle;
 }
 .compact-button button{
   font-size: 80%;
   padding: .5rem .25rem
 }
</style>