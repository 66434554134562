<template>
  <div>
    <form>
      <div class="form-group">
        <label>Prefix beszúrása a kód elé (amennyiben nem létezik)</label>
        <select class="form-control form-control-lg" v-model="userSettings.container.auto_prepend_with_prefix" name="userSettings.container.auto_prepend_with_prefix">
          <option :value="true">Igen</option>
          <option :value="false">Nem</option>
        </select>
      </div>

      <div class="form-group">
        <label>A beolvasott krotália vonalkódja rendelkezik prefix-xel?</label>
        <select class="form-control form-control-lg" v-model="userSettings.container.has_prefix" name="userSettings.container.has_prefix">
          <option v-for="option in userSettings.container.has_prefix_options" :key="option.key" :value="option.key">{{ option.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label>Krotália vonalkód prefix</label>
        <input type="text"
               v-model="userSettings.container.prefix"
               name="userSettings.container.prefix"
               placeholder=""
               class="form-control form-control-lg"
               :class="{ 'is-invalid': $v.userSettings.container.prefix.$invalid }"
               v-model.trim="userSettings.container.prefix"
        />
        <div class="invalid-feedback" v-if="!$v.userSettings.container.prefix.require">Kötelező kitölteni</div>
      </div>
      <div class="form-group">
        <label>Rögzítés vonalkód</label>
        <input type="text"
               v-model="userSettings.barcode_instant_save"
               name="userSettings.barcode_instant_save"
               placeholder=""
               class="form-control form-control-lg"
               :class="{ 'is-invalid': $v.userSettings.barcode_instant_save.$invalid }"
               v-model.trim="userSettings.barcode_instant_save"
        />
        <div class="invalid-feedback" v-if="!$v.userSettings.barcode_instant_save.require">Kötelező kitölteni</div>
      </div>
      <div class="form-group">
        <label>Elvetés vonalkód</label>
        <input type="text"
               v-model="userSettings.barcode_instant_delete"
               name="userSettings.barcode_instant_delete"
               placeholder=""
               class="form-control form-control-lg"
               :class="{ 'is-invalid': $v.userSettings.barcode_instant_delete.$invalid }"
               v-model.trim="userSettings.barcode_instant_delete"
        />
        <div class="invalid-feedback" v-if="!$v.userSettings.barcode_instant_delete.require">Kötelező kitölteni</div>
      </div>
      <div class="form-group">
        <label>Értesítési e-mail cím</label>
        <input type="text"
               v-model="userSettings.notify.email"
               name="userSettings.notify.email"
               placeholder=""
               class="form-control form-control-lg"
               :class="{ 'is-invalid': $v.userSettings.notify.email.$invalid }"
               v-model.trim="userSettings.notify.email"
        />
        <div class="invalid-feedback" v-if="!$v.userSettings.notify.email.email">Érvénytelen e-mail cím</div>
      </div>

      <div class="form-group">
        <label>Értesítési API kulcs</label>
        <input type="text"
               v-model="userSettings.notify.api_key"
               name="userSettings.notify.apy_key"
               placeholder=""
               class="form-control form-control-lg"
               :class="{ 'is-invalid': $v.userSettings.notify.api_key.$invalid }"
               v-model.trim="userSettings.notify.api_key"
        />
        <div class="invalid-feedback" v-if="!$v.userSettings.notify.api_key.minLength">Érvénytelen kulcs formátum</div>
      </div>

      <div class="form-group">
        <label>Kompakt mód</label>
        <select class="form-control form-control-lg" v-model="userSettings.compact_mode" name="userSettings.compact_mode">
          <option :value="true">Igen</option>
          <option :value="false">Nem</option>
        </select>
      </div>
      <div class="form-group">
        <label>Kamera kiválasztása</label>
        <select class="form-control form-control-lg" v-model="userSettings.selected_video_source" name="userSettings.selected_video_source" @change="startCapture">
          <option v-for="videoSource in videoSources" v-bind:value="videoSource.id" v-bind:key="videoSource.id">{{ videoSource.label}}</option>
        </select>
        <div class="mt-2">
          <video id="video"></video>
        </div>
      </div>

      <div class="form-group">
        <button type="button" class="btn btn-block btn-primary"  @click="saveUserSettings" :disabled="$v.userSettings.$invalid">Beállítások mentése</button>
      </div>
    </form>
  </div>
</template>

<script>
import {BrowserMultiFormatReader, BarcodeFormat, DecodeHintType} from '@zxing/library';
import { email} from 'vuelidate/lib/validators'

const hints = new Map();

const formats = [
  BarcodeFormat.CODE_39,
  BarcodeFormat.CODE_93,
  BarcodeFormat.CODE_128,
  BarcodeFormat.EAN_8,
  BarcodeFormat.EAN_13,
  BarcodeFormat.CODABAR,
  BarcodeFormat.UPC_A,
  BarcodeFormat.UPC_E,
  BarcodeFormat.UPC_EAN_EXTENSION
];

hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)

const codeReader = new BrowserMultiFormatReader(hints, 2000);
export default {
  name: "Settings",
  components: {
  },
  data(){
    return {
      userSettings: this.$project.userSettings.getModel(),
      videoSources: []
    }
  },
  validations: {
    userSettings: {
      barcode_instant_save: {
        minLength: 3
      },
      barcode_instant_delete: {
        minLength: 3
      },
      container: {
        prefix: {
          mintLength: 2,
        }
      },
      notify: {
        email: {
          email: email
        },
        api_key: {
          minLength: 10
        }
      },
    }
  },
  mounted() {
    const component = this;
    component.userSettings = Object.assign({}, this.$userSettings);

    codeReader.listVideoInputDevices().then(videoInputDevices => {
      if (videoInputDevices.length === 0) {
        return;
      }

      videoInputDevices.forEach((element) => {
        console.log(element);
        component.videoSources.push({
          id: element.deviceId,
          label: element.label
        });
      });
      if(component.userSettings.selected_video_source !== null) {
        return component.startCapture();
      }

      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: {
            exact: 'environment'
          }
        }
      }).then(function(device){
        component.$swal.fire({
          icon: 'success',
          title: 'Hátsó kamera beállítva!',
          toast: true,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });

        component.userSettings.selected_video_source = device.deviceId;
        console.log(device);
        console.log(component.videoSources)
        component.startCapture();
      }).catch(function(error){
        component.$swal.fire({
          icon: 'error',
          title: 'Nem található hátsó kamera, a következő kiválasztva: ' + component.videoSources[0].label,
          timer: 3000,
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
        });
        console.log(error);
        console.log(component.videoSources[0].id);
        component.userSettings.selected_video_source = component.videoSources[0].id;
        component.startCapture();
      });
    });
  },
  methods: {
    startCapture: function(){
      const component = this;
      codeReader.reset();
      codeReader.timeBetweenDecodingAttempts = 5000;
      codeReader.decodeFromVideoDevice(this.userSettings.selected_video_source, 'video', device => {
        if(device){
          component.userSettings.selected_video_source = device.id

        }
      })
    },
    saveUserSettings: function(){
        this.$userSettings = Object.assign({}, this.userSettings);
        this.$project.emailQueue.resetAllBusy();

        this.$swal.fire({
          icon: 'success',
          title: 'Beállítások frissítve!',
          timer: 3000,
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
        });
    }
  }
}
</script>

<style scoped>
video {
  width: 100%    !important;
  max-width: 100%;
  height: auto   !important;
}
</style>