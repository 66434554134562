<template>
  <div class="d-flex" style="order: 1">
    <span v-if="$project.emailQueue.waitingJobCount()" class="badge badge-default mr-3"><strong>{{ $project.emailQueue.waitingJobCount() }}</strong> e-mail várakozik</span>
    <span class="badge badge-success" v-if="$runTime.isOnline">Online</span>
    <span class="badge badge-danger" v-if="!$runTime.isOnline">Offline</span>
  </div>
</template>

<script>
export default {
  name: "EmailQueue"
}
</script>

<style scoped>

</style>