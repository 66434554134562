
<template>
  <html>
    <head>
      <link rel="apple-touch-icon" sizes="180x180" href="img/icons/apple-touch-icon.png">
      <link rel="icon" type="image/png" sizes="32x32" href="img/icons/favicon-32x32.png">
      <link rel="icon" type="image/png" sizes="16x16" href="img/icons/favicon-16x16.png">
      <link rel="manifest" href="img/icons/site.webmanifest">
      <link rel="mask-icon" href="img/icons/safari-pinned-tab.svg" color="#5bbad5">
      <meta name="msapplication-TileColor" content="#da532c">
      <meta name="theme-color" content="#ffffff">
    </head>
    <body>
      <div id="app">
        <mdb-navbar hamburger expand="xl" color="indigo" dark>
          <email-queue></email-queue>
          <mdb-navbar-toggler>
            <mdb-navbar-nav center>
              <mdb-nav-item to="/">Adatbázisok</mdb-nav-item>
              <mdb-nav-item to="/settings">Beállítások</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>

        </mdb-navbar>
        <div class="container" :class="{'mt-3': !$userSettings.compact_mode, 'mt-1 p-1': $userSettings.compact_mode}">
          <router-view/>
        </div>

        <div id="update-ui" v-if="showUpdateUI" class="fixed-bottom bg-light p-2">
          <div class="container">
            <h5 class="my-2">Új verzió érhető el az alkalmazásból.</h5>
            <div class="row">
              <div class="col">
                <button class="btn btn-danger btn-block" @click="showUpdateUI = false">Mégsem</button>
              </div>
              <div class="col">
                <button class="btn btn-block btn-primary" @click="accept()">Frissítés most</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </html>
</template>
<script>
import { mdbNavbar, mdbNavbarNav, mdbNavbarToggler, mdbNavItem} from 'mdbvue';
import EmailQueue from "@/components/EmailQueue";

export default {
  name: 'app',
  components: {
    EmailQueue,
    mdbNavbar,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbNavItem
  },
  data() {
    return {
      showUpdateUI: false
    }
  },
  methods: {
    async accept() {
      this.showUpdateUI = false;
      this.$workbox.messageSkipWaiting();
      console.log("skip waiting");
    }
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        console.log("wb event waiting");
        this.showUpdateUI = true;
      });
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>