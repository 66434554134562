<template>
  <div>
      <button class="btn btn-success btn-block btn-lg" @click="$parent.$emit('database.open.form', null)">Új adatbázis</button>
      <div class="mt-3">
          <div class="card mb-3" v-for="database in $databases" v-bind:key="database.id">
            <div class="card-body">
              <div class="row">
                <div class="col-xs-12 col-lg-8 text-lg-left">
                  <h4 class="mb-lg-0">{{ database.name}}</h4>
                  <h6 class="mb-lg-0">Rögzített elemek: {{ database.barcodes !== undefined ? database.barcodes.length : 0}}</h6>
                  <hr class="d-lg-none d-block">
                </div>
                <div class="col-xs-12 col-lg-4">
                  <div class="row justify-content-end">
                    <div class="col ">
                      <button class="btn btn-danger btn-block m-0" @click="deleteDatabase(database.id)"><i class="fa fa-times"></i></button>
                    </div>
                    <div class="col" v-if="database.is_open">
                      <button class="btn btn-warning btn-block m-0" @click="$parent.$emit('database.open.form', database.id, is_update = true)"><i class="fa fa-edit"></i></button>
                    </div>
                    <div class="col" v-if="database.is_open">
                      <router-link class="btn btn-success btn-block m-0 text-white" :to="{ path: `/manage/${database.id}` }"><i class="fa fa-arrow-right"></i></router-link>
                    </div>
                    <div class="col" v-if="!database.is_open && database.barcodes !== undefined">
                      <download-csv class="btn btn-info btn-block m-0"
                                    :data   = "database.barcodes"
                                    delimiter = ","
                                    v-bind:fields="['container', 'tube']"
                                    :advancedOptions="{header: false}"
                                    :name="database.name +'.csv'">
                        <i class="fa fa-download"></i>
                      </download-csv>
                    </div>
                  </div>
                </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'DatabaseList',
  components: {
  },
  methods: {
    deleteDatabase: function(id){
      this.$swal.fire({
        title: 'Biztosan törölni szeretnéd?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Igen',
        cancelButtonText: 'Nem'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$project.database.deleteById(id)
        }
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
